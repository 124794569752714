@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/index.css */

@layer utilities {
  input[type="range"]::-webkit-slider-thumb {
    @apply w-5 h-5 bg-primary rounded-full cursor-pointer;
  }

  input[type="range"]::-moz-range-thumb {
    @apply w-5 h-5 bg-primary rounded-full cursor-pointer;
  }

  input[type="range"]::-ms-thumb {
    @apply w-5 h-5 bg-primary rounded-full cursor-pointer;
  }
}


:root {
    /* --color-primary: #dc2626; */
    /* --color-primary-content: #ffffff; */
    /* Определите остальные переменные по необходимости */
  }

  .bitch-page .lang-eng {
    font-family: "Noto Sans" !important;
  }
  .bitch-page .lang-geo {
    font-family: "Noto Sans Georgian" !important;
  }

  .bitch-page .btn {
    color: white;
  }

  .bitch-page .label {
    @apply text-center justify-center text-white font-medium;
  }

  .bitch-page .input {
    border-color: #1e526b;
    background-color: rgb(0,0,0, 0.6) ;
    @apply input-lg text-center text-white;
  }

  .bitch-page .btn {
    @apply text-white font-bold;
    background-color: rgb(0, 0, 0, 0.7);
    border-width: 1.8px;
    transition: all 0.4s;
    /* border-color: #1e526b;
    background-color: rgb(0,0,0, 0.6) ;
    @apply input-md text-center; */
  }

  .bitch-page .btn-pink {
    border-color: #980084;
    transition: all 0.4s;
     
  }

  .bitch-page .btn-pink.btn-shadow,   .bitch-page .btn-pink.btn-shadow-hover:hover {
    box-shadow:  0px 0px 7px 3px #d605ba, inset 0px 0px 18px 0px #d605ba;
  }

  .bitch-page .btn-pink-shadow-hover:hover {

    border-color: #980084;
    box-shadow:  0px 0px 7px 3px #d605ba, inset 0px 0px 18px 0px #d605ba;
  }

  .bitch-page .btn-blue {
    border-color: #2b7d9f;
    transition: all 0.4s;
  } 

  .bitch-page .btn-blue.btn-shadow, .bitch-page .btn-blue.btn-shadow-hover:hover  {
    box-shadow:  0px 0px 7px 3px #3dbaee, inset 0px 0px 18px 0px #3dbaee;
  }

  .btn-blue-text-shadow {
    text-shadow:  0.5px 0.5px 1px white;
    color: #3dbaee;
  }

  .bitch-page .btn-shadow:hover {
    box-shadow: none;

  }
  .bitch-page .btn-primary {
    border-radius: 14px;
    /* background-color: #2b7d9f; */
    background: linear-gradient(90deg, rgba(29,108,178,1) 0%, rgba(126,37,241,1) 35%, rgba(237,25,147,1) 100%);
    border: none;
  }

  .bitch-page .input:focus {
    outline: none; /* Убираем стандартное outline */
    box-shadow:  0px 0px 7px 3px #3dbaee, inset 0px 0px 18px 0px #3dbaee;
  }
 

  .bitch-page .btn-primary:focus {
    outline: none; /* Убираем стандартное outline */
    box-shadow: 0px 0px 7px 3px #d605ba, inset 0px 0px 18px 0px #d605ba; /* Добавляем тень */
  }
 
  .bitch-page .text-error {
    color: #d605ba; 
  }

  .bitch-page .btn-rounded {
    border-radius: 24px;
  }


  .bitch-page .progress-bar {
    border-radius: 15px;
    box-shadow: 0px 0px 7px 3px #280123;
    background: linear-gradient(90deg, rgba(29,108,178,1) 0%, rgba(126,37,241,1) 35%, rgba(237,25,147,1) 100%);
    display: flex;
    /* justify-content: center; */
    position: relative;
    overflow: hidden;
    height: 25px;
  }
  .bitch-page .progress-bar-value {
    font-weight: 800;
    position: absolute;
    top: 0px;
    left: 46%;
  }
  .bitch-page .progress-bar-hidden {
    position: absolute;
    background-color: #34012d;
    height: 100%;
    right: 0;
  }

  .bitch-page .label-text{
    color: white;
  }
  .bitch-page .checkbox:checked {
    content: "✔"; /* Галочка */
    display: block;
    color: white;
    font-size: 14px;
    text-align: center;
    line-height: 24px; 
  }


  /* pr
  
  ogress-bar-hidden */

  /*  */